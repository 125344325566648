import { ConstantData } from './ConstantData';

export const filterableSources = {
	unit_types:{
		label: 'Rooms',
		filterType: ConstantData.unitFilterTypes.checkedList,
		unitRelationDataType: 'unitHomeTypes',
		dataListItemLabelField: 'name'
	},
	floors: {
		label: 'Floors',
		filterType: ConstantData.unitFilterTypes.checkedList,
		unitRelationDataType: 'unitFloors',
		dataListItemLabelField: 'name'
	},
	size: {
		label: 'Size',
		filterType: ConstantData.unitFilterTypes.range,
		unitField: 'size',
		rangeMinimumValue: 410,
		rangeMaximumValue: 1272,
		rangeStep: 10,
		displayedDataFormattingFunction: ( value ) => {
			return `${value} sqft`
		},
	},
};

export const filterableSourcesHeaderButtons = [
	{
		label: 'Rooms',
		filterType: ConstantData.unitFilterTypes.checkedList,
		unitRelationDataType: 'unitHomeTypes',
		dataListItemLabelField: 'name',
	},
	{
		label: 'Floors',
		filterType: ConstantData.unitFilterTypes.checkedList,
		unitRelationDataType: 'unitFloors',
		dataListItemLabelField: 'name',
	},
    {
        label: 'Size',
        unitRelationDataType: "size",
        filterType: ConstantData.unitFilterTypes.range,
        unitField: 'size',
        rangeMinimumValue: 410,
        rangeMaximumValue: 1272,
        rangeStep: 10,
        displayedDataFormattingFunction: ( value ) => {
            return `${value} sqft`
        },
    },
];