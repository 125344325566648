import React, {

} from 'react';
import {
	Link, useLocation
} from 'react-router-dom';

// import {
// 	motion
// } from 'framer-motion';

import {
	createUseStyles
} from 'react-jss';

import ThemeData from '../data/ThemeData';
import HeaderContextData from '../data/HeaderContextData';

import SavedHomesTitle from './SavedHomesTitle';
import { useQuery } from '@apollo/client';
import QueryData from 'data/QueryData';

const useStyles = createUseStyles(
	{
		header: {
			display: 'flex',
			position: 'relative',
			zIndex: 10,
			justifyContent: 'space-between',
			boxSizing: 'border-box',
			height: 'var( --header-height )',
			background: 'linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0, 0, 0, 0.6) 50%, rgba(0,0,0,1) 100%)',
			borderBottom: ThemeData.colours.paneBorders,
		},
		headerSection: {
			flex: '1 0 auto',
			height: 100,
			width: 350,
		},
		headerLeft: {
			display: 'flex',
			alignItems: 'center',
		},
		headerMiddle: {
			margin: '0 auto',
			display: props => props.display,
			maxHeight: 100,
			background: 'no-repeat center',
			backgroundImage: props => `url( ${props.logoImage} )`,
			backgroundSize: '30%',

			cursor: 'pointer',
		},
		headerRight: {
			display: 'flex',
			alignItems: '',
			justifyContent: 'flex-end',

			...ThemeData.typography.nameplate,
		},
		headerRightItem: {
			height: '99px',

			lineHeight: '100px',

			'& > p': {
				margin: 0,
			},
		},
		savedHomes: {
			flex: '0 0 auto',
			display: props => props.display,
			width: 175,

			marginRight: 50,

			cursor: 'pointer',
		},
		register: {
			display: props => props.display,
			flex: '0 0 auto',

			width: 175,
			lineHeight: '100px',

			...ThemeData.ux.fillToRight,

			borderLeft: `1px ${ThemeData.colours.white50} solid`,

			cursor: 'pointer',

			'& p': {
				margin: 0,
			}
		},
	},
	{ name: 'HeaderComponents' }
);


function HeaderComponents( { children, headerContext, ...props } ) {
	const { backgroundColor, display } = props;
	const { data, loading } = useQuery(QueryData.global);
	const logoImage = !loading &&  data.global.site_logo.url;
	const classes = useStyles({backgroundColor, display, logoImage});
	return (
		<>
			<div className={classes.header}>
				<div className={`${classes.headerSection} ${classes.headerLeft}`} >
					{children}
				</div>
				<Link
					to="/"
					onClick={() => props.setIsUnitDetailOpen && props.setIsUnitDetailOpen(false)}
					className={`${classes.headerSection} ${classes.headerMiddle}`}>
				</Link>
				<div className={`${classes.headerSection} ${classes.headerRight} ${headerContext && headerContext === HeaderContextData.mainMenuHeaderContext ? 'mainMenuHeader' : ''}`}>
					<SavedHomesTitle
						className={`${classes.savedHomes} ${classes.headerRightItem}`}
						onClick={() => props.toggleIsSavedHomesSlideoutOpen()}
					/>
					<div
						className={`${classes.register} ${classes.headerRightItem}`}
						onClick={() => window.open('https://www.landaglobal.com/lucent/', '_blank')} >
						<p id='register'>Register</p>
					</div>
				</div>
			</div>
		</>
	)

}


export default HeaderComponents;