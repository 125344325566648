import React from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	useQuery,
} from "@apollo/client";

import {
	motion,
	AnimatePresence,
} from "framer-motion";




import ThemeData from "data/ThemeData";
import QueryData from "data/QueryData";


import useSavedHomes from "hooks/useSavedHomes";
import useOpenClosePane from "hooks/useOpenClosePane";


import ButtonOutline from "./ButtonOutline";
import ButtonGroup from "./ButtonGroup";
import ClearConfirmationDialogGeneric from "./ClearConfirmationDialogGeneric";
import SavedHomesTitle from "./SavedHomesTitle";
import UnitDetailListItemMini from "./UnitDetailListItemMini";


import xWhiteMedium from "img/x_white_medium.svg";
import neuMountainscape from "img/neu-mountainscape.svg";
import { ReactComponent as IconTrash } from "img/icon_trash.svg";



const jsStyles = {
	wrapper: {
		position: "fixed",
		top: 0,
		right: 0,
		background: ThemeData.colours.carvenGreen,
		backgroundSize: 'cover',
		width: 450,
		height: "100%",
		zIndex: 999,
		boxSizing: 'border-box',
		contentVisibility: 'visible',

		padding: 25,
		paddingTop: 30,

		color: ThemeData.colours.white,
		//backgroundColor: ThemeData.colours.primaryBg,
		...ThemeData.styles.hoverPaneBoxShadow,

		letterSpacing: '.025em',

	},
	openMark: {
		position: 'absolute',
		top: 30,
		left: -40,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',

		width: 40,
		height: 40,

		backgroundColor: ThemeData.colours.primaryBg,

		cursor: 'pointer',

		zIndex: -1,
	},
	bgAmp: {
		zIndex: -1,
		position: 'absolute',
		left: -50,
		bottom: -100,

		color: ThemeData.colours.primaryAccent08,
		fontFamily: ThemeData.fonts.copy,
		fontSize: 500,
	},
	contentContainer: {
		display: 'flex',
		flexDirection: 'column',
		contentVisibility: 'visible',

		width: '100%',
		height: '100%',
	},
	closeX: {
		position: 'absolute',
		top: 20,
		right: 20,

		cursor: 'pointer',
	},
	savedHomesTitle: {
		textAlign: 'left',
		color: ThemeData.colours.black
	},
	top: {
		flex: '0 0 auto',

		height: 50,
	},
	middle: {
		flex: '1 0 auto',

		maxHeight: 'calc( 100vh - 290px )',

		overflowX: 'clip',
		overflowY: 'auto',
		'&::-webkit-scrollbar':{
			display: 'none'
		},
		scrollbarWidth: 'none',
	},
	bottom: {
		flex: '0 0 auto',

		boxSizing: 'border-box',
		paddingTop: 25,

		height: 102,

		borderTop: `1px ${ThemeData.colours.white50} solid`,
	},
	trashIcon: {
		marginInline: 'auto',
		marginBottom: 25,
		marginTop: 25,
		borderRadius: 100,
		width: 150,
		'& > div':{
			marginLeft: 5,
			fontSize: 10
		}
	},
	bottomButton: {
		justifyContent: 'center',
		margin: 5,
		fontSize: 14,
		letterSpacing: '.1em',
		color: ThemeData.colours.black
	},
	bgMountainscapeClipper: {
		position: 'absolute',
		left: 0,
		right: 0,
		bottom: 0,
		height: 90,
		width: '100%',
		overflow: 'clip',

		zIndex: -1,
	},
	bgMountainscapeWrapper: {
		position: 'relative',
		bottom: 0,
		left: -12,
		right: 0,

		width: '105%',
		overflow: 'clip',

		zIndex: -1,
	},
	bgMountainscapeImg: {
		width: '100%',
		// paddingBottom: 15,
	},
};

const useStyles = createUseStyles(
	jsStyles,
	{
		name: "SavedHomesSlideout",
	},
)



const wrapperAnimVariants = {
	open: {
		x: 0,
	},
	closed: {
		x: "110%",
	},
}



const RenderSavedHomesSlideout = ( classes, data, error, isOpen, toggleIsOpen, hooks, props ) => {

	if ( error ) {
		console.log( "RenderSavedHomesSlideout", error.message );
	}

	function stopPropagation( e ) {
		e.stopPropagation();
	}

	return (
		<motion.div
			id='saved-homes-slideout'
			className={classes.wrapper}
			initial={"closed"}
			animate={isOpen ? "open" : "closed"}
			variants={wrapperAnimVariants}
			transition={{
				type: "linear",
				duration: .5,
			}}
			onMouseDown={( e ) => stopPropagation( e )}
			onMouseUp={( e ) => stopPropagation( e )}
		>
			<div className={classes.contentContainer}>
				<div className={classes.top}>
					<SavedHomesTitle
						className={classes.savedHomesTitle}
						titleText="Save and Compare"
						numberBgColor={ThemeData.colours.secondaryAccent}
					/>
					<div
						className={classes.closeX}
						onClick={() => toggleIsOpen()}>
						<img src={xWhiteMedium} alt="Slide Away Saved Homes" />
					</div>
				</div>
				<div className={classes.middle}>
					<AnimatePresence>
						{data && data.units && hooks.savedHomes.map( savedHomeUnit => {

							let unitIdx = data.units.findIndex( elm => elm.id === savedHomeUnit.id );

							if ( unitIdx !== -1 )
								return <UnitDetailListItemMini
									data={data.units[unitIdx]}
									key={unitIdx}
									toggleSavedHome={() => hooks.toggleSavedHome( savedHomeUnit.id )}
								/>;

							return <></>;

						}
						)}
					</AnimatePresence>
				</div>
				<ButtonOutline
					className={classes.trashIcon}
					borderColour={ThemeData.colours.mainBorder}
					textColour={ThemeData.colours.primaryAccent}
					hoverTextColour={ThemeData.colours.primaryFontHighestContrast}
					onClick={() => hooks.setIsClearConfirmationDialogOpenToOpen()}
				>
					<IconTrash /> <div>Remove All</div>
				</ButtonOutline>
				<div className={classes.bottom}>
					<ButtonGroup
						className={classes.bottomButtons}
						justifyContent="center"
					>
						<ButtonOutline
							id="compare-saved-homes-button"
							borderColour={ThemeData.colours.white}
							firstInColl
							className={classes.bottomButton}
							onClick={() => {
								props.toggleIsSavedHomesCompareOpen();
							}}
						>
							Compare Homes
						</ButtonOutline>
						<ButtonOutline
							borderColour={ThemeData.colours.white}
							firstInColl
							className={classes.bottomButton}
							onClick={() => props.toggleIsRegisterOpen()}
						>
							Send To Email
						</ButtonOutline>
					</ButtonGroup>
					<ClearConfirmationDialogGeneric
						dataTypeToClearName={"Saved Homes"}
						isClearConfirmationDialogOpen={hooks.isClearConfirmationDialogOpen}
						setIsClearConfirmationDialogOpenToClosed={hooks.setIsClearConfirmationDialogOpenToClosed}
						afterClearConfirmationPerformFunction={hooks.clearSavedHomes}
					/>
				</div>
			</div>
			{/* <div className={classes.bgMountainscapeClipper}>
				<div className={classes.bgMountainscapeWrapper}>
					<img
						className={classes.bgMountainscapeImg}
						src={neuMountainscape}
						alt="Mountainscape BG"
					/>
				</div>
			</div> */}
		</motion.div>
	)
}



const SavedHomesSlideout = ( { isOpen, toggleIsOpen, ...props } ) => {

	const classes = useStyles();


	const { savedHomes, toggleSavedHome, clearSavedHomes } = useSavedHomes();


	const {
		isClearConfirmationDialogOpen,
		setIsClearConfirmationDialogOpenToOpen,
		setIsClearConfirmationDialogOpenToClosed,
	} = useOpenClosePane( "ClearConfirmationDialog" );


	let hooks = {
		savedHomes,
		toggleSavedHome,
		clearSavedHomes,
		isClearConfirmationDialogOpen,
		setIsClearConfirmationDialogOpenToOpen,
		setIsClearConfirmationDialogOpenToClosed,
	};


	const { loading, error, data } = useQuery( QueryData.units );

	if ( loading ) return RenderSavedHomesSlideout( classes, null, null, isOpen, toggleIsOpen, hooks, props );
	if ( error ) return RenderSavedHomesSlideout( classes, null, error, isOpen, toggleIsOpen, hooks, props );

	// console.log( "units", data );

	return RenderSavedHomesSlideout( classes, data, null, isOpen, toggleIsOpen, hooks, props );

}



export default SavedHomesSlideout;