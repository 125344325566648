import React, {

} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	// useQuery,
	// gql,
} from "@apollo/client";

import {
	// motion
} from "framer-motion";


import ThemeData from "data/ThemeData";
import ConstantData from "data/ConstantData";


import UtilFcns from "util/UtilFcns";
import useSiteFeatureToggles from "hooks/useSiteFeatureToggles";


import { ReactComponent as BedIcon } from "../img/icon_bed.svg";
import facingArrowIcon from "img/icon_facing_arrow.svg";



const useStyles = createUseStyles(
	{
		wrapper: {
			// padding: 20,
			margin: '0 20px',
			textAlign: 'left',
		},
		detailListItemsRow: ( props ) => ( {
			margin: 0,
			marginBlock: props.marginTopItemsRow,
			padding: 0,

			display: 'flex',
			justifyContent: 'left',

			listStyleType: 'none',

			textAlign: 'left',
			letterSpacing: '.05em',
			'@media (max-width: 1200px)':{
				marginBlock: 20
			}
		} ),
		detailList: {
		},
		detailListItem: {
			width: 220,

			flex: '0 1 auto',
		},
		detailListItemWider: {
			width: 200,
		},
		detailListItemIcon: {
			color: ThemeData.colours.primaryAccent,
		},
		detailListItemInline: {
			display: 'inline-block',
			marginRight: 10,
		},
		detailListItemTitle: {
			// color: ThemeData.colours.black,
			fontFamily: ThemeData.fonts.titleSub,
			fontSize: 12,
			textTransform: 'uppercase',
			'@media (min-width: 3800px)':{
				fontSize: '25px',
				height: '50px'
			},
		},
		detailListItemData: {
			marginTop: 10,
			// color: ThemeData.colours.black,
			letterSpacing: '.05em',
			fontSize: 16,
			'@media (min-width: 3800px)':{
				fontSize: '30px',
			},
		},
		detailListItemDataModifier: {
			fontFamily: ThemeData.fonts.copy,
		},
	},
	{
		name: "UnitInfoIconList",
	}
)


const UnitInfoIconList = ( { dataUnit, className, ...props } ) => {

	const classes = useStyles( props );

	const siteFeatureToggles = useSiteFeatureToggles();

	const totalSqft = (dataUnit) => {
		let interior_sqft_lower = dataUnit.interior_sqft;
		let interior_sqft_upper = dataUnit.interior_sqft;
		let exterior_sqft_lower = dataUnit.exterior_sqft;
		let exterior_sqft_upper = dataUnit.exterior_sqft;
		let showRange = false;

		if (dataUnit.interior_sqft.includes("-")) {
			interior_sqft_lower = dataUnit.interior_sqft.split('-')[0]
			interior_sqft_upper = dataUnit.interior_sqft.split('-')[1]
			showRange = true;
		}
		if (dataUnit.exterior_sqft.includes("-")) {
			exterior_sqft_lower = dataUnit.exterior_sqft.split('-')[0]
			exterior_sqft_upper = dataUnit.exterior_sqft.split('-')[1]
			showRange = true;
		}


		if (dataUnit.name == 'A3c') {
			return '505-564';
		} else if (dataUnit.name == "A3d'") {
			return '493-619';
		} else if (dataUnit.name == 'C3') {
			return '730-787';
		} else if (showRange) {
			return `${UtilFcns.parseFloatSafe( interior_sqft_lower ) + UtilFcns.parseFloatSafe( exterior_sqft_lower )} - ${UtilFcns.parseFloatSafe( interior_sqft_upper ) + UtilFcns.parseFloatSafe( exterior_sqft_upper )}`
		} else {
			return UtilFcns.parseFloatSafe( dataUnit.interior_sqft ) + UtilFcns.parseFloatSafe( dataUnit.exterior_sqft )
		}
	};


	return (
		<div className={`${classes.wrapper} ${className}`}>
			<ul className={`${classes.detailListItemsRow} ${classes.detailList}`} >
				<li className={classes.detailListItem}>
					<div className={classes.detailListItemTitle}>
						Interior
					</div>
					<div className={classes.detailListItemData}>
						{dataUnit && (
							<>
								{dataUnit.interior_sqft} <span className={classes.detailListItemDataModifier}>sqft</span>
							</>
						)}
					</div>
				</li>
				{dataUnit && dataUnit.exterior_sqft && (
					<li className={classes.detailListItem}>
						<div className={classes.detailListItemTitle}>
							Exterior
						</div>
						<div className={classes.detailListItemData}>

							<>
								{dataUnit.exterior_sqft} <span className={classes.detailListItemDataModifier}>sqft</span>
							</>
						</div>
					</li>
				)}
				<li className={classes.detailListItem}>
					<div className={classes.detailListItemTitle}>
						Total Space
					</div>
					<div className={classes.detailListItemData}>
						{dataUnit && (
							<>
								{totalSqft(dataUnit)} <span className={classes.detailListItemDataModifier}>sqft</span>
							</>
						)}
					</div>
				</li>
			</ul>
			<ul className={`${classes.detailListItemsRow} ${classes.detailList}`} >
				{dataUnit && dataUnit.unit_home_type && dataUnit.unit_home_type.number_beds && (
					<li className={`${classes.detailListItem}`}>
						<div className={classes.detailListItemTitle}>
							Bedroom
						</div>
						<div className={`${classes.detailListItemIcon} ${classes.detailListItemInline}`}>
							<BedIcon />
						</div>
						<div className={`${classes.detailListItemData} ${classes.detailListItemInline}`}>
							{dataUnit.unit_home_type.number_beds} <span className={classes.detailListItemDataModifier}>{dataUnit.unit_home_type.text_beds}</span>
						</div>
					</li>
				)}
				{dataUnit && dataUnit.baths && (
					<li className={classes.detailListItem}>
						<div className={classes.detailListItemTitle}>
							Bath
						</div>
						<div className={`${classes.detailListItemData} ${classes.detailListItemInline}`}>
							{dataUnit.baths} <span className={classes.detailListItemDataModifier}>Bath</span>
						</div>
					</li>
				)}
				{props.doShowFacingItem && dataUnit && dataUnit.unit_exposures && (
					<li className={`${classes.detailListItem} ${classes.detailListItemWider}`}>
						<div className={`${classes.detailListItemIcon} ${classes.detailListItemInline}`}>
							<img src={facingArrowIcon} alt="Facing Direction Icon" />
						</div>
						<div className={`${classes.detailListItemData} ${classes.detailListItemInline}`}>
							{dataUnit.unit_exposures && dataUnit.unit_exposures.map( unitExposure => (
								<span key={unitExposure.id}>{unitExposure.direction}</span>
							) )} <span className={classes.detailListItemDataModifier}>facing</span>
						</div>
					</li>
				)}
				{
					siteFeatureToggles.isPricesOn &&
					props.doShowPrice &&
					dataUnit && dataUnit.price &&
					(
						<li className={`${classes.detailListItem} ${classes.detailListItemWider}`}>
							<div className={classes.detailListItemTitle}>
								Price
							</div>
							<div className={`${classes.detailListItemData} ${classes.detailListItemInline}`}>
								<span>{ConstantData.dataFormattingFunctions.prices( dataUnit.price )}</span>
							</div>
						</li>
					)
				}

			</ul>
		</div>
	);
}



UnitInfoIconList.defaultProps = {
	marginTopItemsRow:30,

	doShowFacingItem: false,
	doShowPrice: true,
}

export default UnitInfoIconList;