import React, { useState, useLayoutEffect } from "react";

import {
	createUseStyles
} from "react-jss";

import {
	useQuery,
	// gql,
} from "@apollo/client";

import useSavedHomes from "hooks/useSavedHomes";


import ButtonGroup from "./ButtonGroup";
import ButtonOutline from "./ButtonOutline";
import UnitInfoLine from "./UnitInfoLine";


import ThemeData from "../data/ThemeData";
import NetData from "../data/NetData";
import QueryData from "../data/QueryData";


import { BookMarkButton } from "./BookMarkButton";
import { useLocation } from "react-router-dom";
import { sendGoogleAnalyticsEvent } from "util/sendGoogleAnalyticsEvent";


const useStyles = createUseStyles(
	{
		wrapper: {
			width: 330,
			minHeight: 200,
			display: props => !props.unit  && 'none',
			position: 'absolute',
			right: 100,
			top: 200,
			'@media (min-width: 3800px)':{
				width: 800,
				maxWidth: 1100,
			},

			transition: 'all 2s',

			...ThemeData.styles.hoverCard,
		},
		hidden: {
			display: 'none',
		},
		floorplanImage: {
			minHeight: 190,

			borderBottom: `1px ${ThemeData.colours.blackBg15} solid`,
		},
		'$floorplanImage, $details': {
			borderBottom: `1px ${ThemeData.colours.eggshellTextBg} solid`,
		},
		details: {
			position: 'relative',
			display: 'flex',
			justifyContent: 'space-between',
			minHeight: 63,

			padding: 20,

			textAlign: 'left',

			'& h4': {
				margin: 0,
				padding: 0,
				color: ThemeData.colours.grey,
				fontFamily: ThemeData.fonts.title,
				fontSize: '24px',
			},

			'& p': {
			},
		},
		unitInfoLine: {
			marginTop: 10,

			color: ThemeData.colours.black,

			fontFamily: ThemeData.fonts.copy,
			fontSize: '12px',
			letterSpacing: '0.05em',
			'@media (min-width: 3800px)':{
				fontSize: '36px',
				height: '70px'
			},
		},
		saveHomePlus: {
			position: 'absolute',
			top: 16,
			right: 20,

			width: 16,
			height: 16,

			// background: `center no-repeat url(${saveHomePlusLight})`,
		},
		buttonArea: {
			paddingInline: 20,
			paddingBlockEnd: 20,

			borderBottom: `1px ${ThemeData.colours.blackBg15} solid`,
		},
		image: {
			// width: 300,
			height: 187,
			'@media (min-width: 3800px)':{
				objectFit: 'cover',
				width: '800px',
				height: '400px',
			},
		},
		moreDetailButton: {
			padding: 20,
			'@media (min-width: 3800px)':{
				fontSize: '32px',
				padding: 40
			},
		},
		secondaryButton: {
			'@media (min-width: 3800px)':{
				fontSize: '32px',
				padding: 40,
				marginTop: 30
			},
		},
		bookMark: {
			'@media (min-width: 3800px)':{
				width: 60,
				height: 60
			},
		}
	},
	{
		name: "UnitDetailCard",
	}
)

function RenderUnitDetailCard( selectedUnitId, unit, classes, error, toggleUnitDetailPaneFcn, hooks, location, props ) {

	const doDisplayCard = !( selectedUnitId === -1 );

	/* Measuring screenwidth to resize bookmark components because height/width must be adjusted
	via. props. */
	const useWindowSize = () => {
		const [size, setSize] = useState([0, 0]);
		useLayoutEffect(() => {
		  function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		  }
		  window.addEventListener('resize', updateSize);
		  updateSize();
		  return () => window.removeEventListener('resize', updateSize);
		}, []);
		return size;
	  }
	  const screenWidth = useWindowSize()[0];
	
	return (
		<div id="unit-detail-card" className={`${classes.wrapper} ${doDisplayCard ? "" : classes.hidden}`}>
			<div className={classes.floorplanImage}>
				{unit && unit.unit_plan_type && unit.unit_plan_type.floorplan_image &&
					<img
						src={`${NetData.imgUri( unit.unit_plan_type.floorplan_image.formats && unit.unit_plan_type.floorplan_image.formats.small ?
							unit.unit_plan_type.floorplan_image.formats.small.url :
							unit.unit_plan_type.floorplan_image.url )} `}
						alt={unit.unit_plan_type.floorplan_image.alternativeText}
						className={classes.image}
					/>
				}
				{( !unit || !unit.unit_plan_type || !unit.unit_plan_type.floorplan_image ) &&
					<div className={classes.floorplanSpacer} />
					// <img
					// 	src={`${apiUri}/uploads/floorplan_placeholder_85dd01fc30.png`}
					// 	alt='floorplan placeholder'
					// 	width={331}
					// 	height={187}
					// />
				}
			</div>
			<div className={classes.details}>
				<div>
					<h4>{unit && `${unit.unit_plan_type.name}`}</h4>
					<div className={classes.unitInfoLine}>{unit && <UnitInfoLine
						data={unit}
					/>}</div>
				</div>
				{/* <ButtonOutline
					className={classes.saveHomePlus}
					fontSize={25}
					textColour={ThemeData.colours.primaryAccent}
					backgroundColour={ThemeData.colours.white75}
					hoverTextColour={ThemeData.colours.primaryFontHighestContrast}
					hoverBackgroundColour={ThemeData.colours.primaryFontHighestConstrast}
					borderColour={ThemeData.colours.lighterBorderDarkOnLight}
					isCircle={true}
					// firstInColl

					isActive={hooks.savedHomesIncludes( unit && unit.id )}
					onClick={() => hooks.toggleSavedHome( unit && unit.id )}
				>
			</ButtonOutline> */}
					<BookMarkButton id="book-mark-button-unit-detail-card"
						hooks={hooks}
						dataUnit={unit}
						height={screenWidth >= 3800 ? 60 : 30}
						width={screenWidth >= 3800 ? 60 : 30}
					/>
			</div>
			<div className={classes.buttonArea}>
				{/* <ButtonGroup>
					<ButtonOutline
						id="enjoy-the-views-unit-detail-card"
						label="Enjoy the View"
						textColour={ThemeData.colours.primaryAccent}
						hoverTextColour={ThemeData.colours.white}
						borderColour={ThemeData.colours.grey25}
						to="."
						onClick={props.toggleViewStudyPaneFcn}
						firstInColl
						className={classes.secondaryButton}

					/>
					<ButtonOutline
						id="unit-detail-card-ttt"
						label="Take the Tour"
						textColour={ThemeData.colours.primaryAccent}
						hoverTextColour={ThemeData.colours.white}
						borderColour={ThemeData.colours.grey25}
						onClick={props.toggleVirtualTourPaneFcn}
						to="."
						className={classes.secondaryButton}
					/>
				</ButtonGroup> */}
			</div>
				<ButtonOutline
					id="unit-detail-card-more-details"
					label="More Details"
					textColour={ThemeData.colours.primaryAccent}
					hoverTextColour={ThemeData.colours.white}
					border="none"
					backgroundColour={ThemeData.colours.neuGray}
					onClick={() => {
						toggleUnitDetailPaneFcn();
						sendGoogleAnalyticsEvent(
							`Floor Plan View - ${location.pathname}`,
							`User Clicks ${unit.unit_plan_type.name} Unit ${unit.unit_number}`,
							`${unit.unit_plan_type.name} Unit ${unit.unit_number}`,
							`${unit.unit_plan_type.name} Unit ${unit.unit_number}`
						);
					}}
					className={classes.moreDetailButton}
				/>
		</div>
	);
}


function UnitDetailCard( { selectedUnitId, unit, toggleUnitDetailPaneFcn, ...props } ) {
	const location = useLocation();
	const classes = useStyles({ unit });


	const { savedHomes, toggleSavedHome, savedHomesIncludes } = useSavedHomes();

	let hooks = {
		savedHomes,
		toggleSavedHome,
		savedHomesIncludes
	}

	return RenderUnitDetailCard( selectedUnitId, unit, classes, null, toggleUnitDetailPaneFcn, hooks, location, props );
}



export default UnitDetailCard;